.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.full {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
      width: 30px;
      height: 30px;
      border: 5px solid #eee;
      border-top: 5px solid #1a1b1c;
    }
  }
  .spinner {
    border: 5px solid #1a1b1c;
    border-top: 2px solid #eee;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 0.35s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
