nav {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  box-sizing: border-box;
  z-index: 8;
  background: #1a1b1c;
  max-width: 500px;
  .navWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0;
  }

  a:hover,
  .active {
    .btn {
      &.btnNav {
        background: #222;
        border-radius: 0;
  padding: 26px 0;
  svg {
          transform: scale(1.2);
          transition: 0.15s linear;
          color: rgba(219, 68, 55, 1) !important;
        }
      }
    }
  }
  a {
    display: block;
    width: 25%;
    .btn {
      &.btnNav {
        color: #999;
  padding: 26px 0;
  svg {
          transition: 0.15s linear;
        }
      }
    }
  }
}
.navTop {
  position: sticky;
  top: env(safe-area-inset-top);
  transform: translate(0);
  width: 100%;
  height: 70px;
  color: #fff;
  display: flex;
  align-items: center;
  background: #1a1b1c;
  padding: 16px 0;
  font-size: 15px;
  a {
    width: fit-content;
    height: fit-content;
  }
}
.bulletNotification {
  width: 5px;
  height: 5px;
  display: block;
  border-radius: 50%;
  background: rgba(244, 180, 0, 1);
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.navProfileImage {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  background: #999;
  border-radius: 50px;
}
.fullHeader {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  h1 {
    width: fit-content;
  }
}

.rangeContainer {
  position: relative;
  width: 90%;
  height: 20px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.slider {
  position: relative;
  width: 100%;
}

.track {
  position: absolute;
  height: 3px;
  width: 100%;
  max-width: 100%;
  background: #ddd;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: #555;
}

.range {
  position: absolute;
  height: 3px;
  // border: 3px solid #cbffa3;
  background: linear-gradient(to right, orangered, rgb(219, 68, 55));
  border-radius: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
  opacity: 0;
}

.thumbIndicator {
  position: absolute;
  height: 15px;
  width: 15px;
  border: 2px solid rgb(219, 68, 55);
  background-color: rgba(219, 68, 55, 0.6);
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: auto;
  z-index: 9;
}

.thumb-left {
  z-index: 2;
}

.thumb-right {
  z-index: 1;
}

.thumb-left::-webkit-slider-thumb {
  pointer-events: auto;
  width: 20px;
  height: 20px;
  background: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.thumb-right::-webkit-slider-thumb {
  pointer-events: auto;
  width: 20px;
  height: 20px;
  background: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}
