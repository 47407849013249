.page {
  box-sizing: border-box;
  overflow: hidden;
  min-height: calc(100vh - 65px);
  &.text {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.padding {
    padding: 20px 20px 85px 20px;
  }
}
