.modal {
  position: fixed;
  bottom: -200%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 500px;
  background: transparent;
  z-index: 9;
  padding: 0 10px;
  box-sizing: border-box;
  transition: 0.35s;
  overflow: auto;
  opacity: 0;
  margin: 0 0 0 auto;
  overflow: scroll;
  &.pageAccount {
    width: 100% !important;
    height: 100vh !important;
    background: #222 !important;
    box-sizing: border-box !important;
    padding: 10px !important;
    left: 10px !important;
    transform: translateX(0) !important;
  }
  &.chat {
    background: #222;
  }
  &.age {
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 100%;
    backdrop-filter: blur(10px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    div {
      width: 90%;
      max-width: 500px;
      text-align: center;
      h3 {
        font-size: 40px;
      }
      .btn:last-of-type {
        margin-top: 5px;
      }
    }
  }
  .headerCenter {
    text-align: center;
  }
  ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    li {
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding: 10px 0;
        p {
          width: 100%;
        }
        svg {
          color: #fff;
        }
      }
    }
  }
  &.show {
    bottom: 0px;
    transform: translateX(-50%);
    transition: 0.35s;
    opacity: 1;
  }
  .inviteLink {
    overflow-wrap: break-word;
  }
  .modalBottomCloseButton {
    width: 35%;
    height: 5px;
    display: block;
    background: #ddd;
    margin: 15px auto 0 auto;
    border-radius: 50px;
  }
}
.modalBottom {
  position: fixed;
  bottom: -200%;
  left: 50%;
  transform: translate(-50%,0);
  width: 98%;
  max-width: 500px;
  max-height: 90vh;
  height: fit-content;
  background: #222;
  border-radius: 20px;
  z-index: 9;
  padding: 0 10px;
  box-sizing: border-box;
  transition: 0.35s;
  overflow: auto;
  opacity: 0;
  margin: 0 0 0 auto;
  overflow: scroll;
  .headerCenter {
    text-align: center;
  }
  ul {
    list-style: none;
    list-style-type: none;
    padding: 0;
    li {
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding: 10px 0;
        p {
          width: 100%;
        }
        svg {
          color: #fff;
        }
      }
    }
  }
  &.show {
    bottom: 5px;
    transform: translate(-50%,0);
    transition: 0.35s;
    // display: block !important;
    opacity: 1;
  }
  .inviteLink {
    overflow-wrap: break-word;
  }
  .modalBottomCloseButton {
    width: 35%;
    height: 5px;
    display: block;
    background: #ddd;
    margin: 15px auto 0 auto;
    border-radius: 50px;
  }
}
.backdrop {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.1);
  z-index: 8;
  backdrop-filter: blur(10px);
  transform-origin: bottom;
  &.hidden {
    transform: scale(0);
  }
}
.modalCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 90%;
  max-width: 800px;
  padding: 20px;
  box-sizing: border-box;
  transition: 0.35s;
  background: #222;
  border-radius: 10px;
  z-index: 9;
  overflow: hidden;
  &.show {
    transform: translate(-50%, -50%) scale(1);
  }
  .btnList {
    background: #111;
  }
}
.modalPayment {
  position: fixed;
  top: 10px;
  left: 2%;
  transform: translateY(-150%);
  width: 96%;
  padding: 20px;
  box-sizing: border-box;
  transition: 0.35s;
  background: #333;
  border-radius: 10px;
  z-index: 9;
  overflow: hidden;
  text-align: left;
  &.show {
    transform: translateY(0);
  }
  .btnList {
    background: #111;
  }
}
