.usersResults {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px 0 80px 0;
  .usersResultsScroll {
    overflow: auto !important;
    width: 100%;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: hidden;
    border-radius: 20px;
    width: 96%;
    margin: 0 auto;
  }
}
.userCta {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  border-radius: 20px;
}
.modalBottom {
  h3 {
    text-align: center;
  }
}
.cardUser {
  position: relative;
  height: 20%;
  width: 100%;
  max-width: 500px;
  background: #111;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0 0 8px 0;
  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 15px;
    display: block;
    background: #333;
    margin-bottom: -60px;
  }
  h3 {
    color: #1a1b1c;
  }
}
.cardUserInfo {
  backdrop-filter: blur(10px);
  padding: 5px 10px 10px 10px;
  border-radius: 20px;
  width: calc(100% + 1px);
  box-sizing: border-box;
  position: relative;
  h3 {
    margin: 0 0 10px 0;
    font-weight: 500;
    font-size: 25px;
    color: #fff;
  }
  h4 {
    margin: 10px 0;
    font-weight: 500;
    font-size: 18px;
    color: #fff !important;
    .textSmall {
      padding: 0 0 5px 0;
      display: block;
    }
  }
  p {
    color: #777;
  }
  .basicInfo {
    background: #222;
    width: fit-content;
    padding: 5px 8px;
    border-radius: 50px;
    margin: 2px 2px 2px 0;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 600;
    flex-shrink: 0;
    color: #fff;
    &.available {
      background: rgba(15, 157, 88, 1);
      color: #fff;
    }
    &.busy {
      background: rgba(219, 68, 55, 1);
      color: #fff;
    }
    svg {
      margin: 0 5px 0 0;
      font-size: 16px;
    }
  }
}
