.input {
  position: relative;
  padding: 5px 0;
  overflow: visible;

  &.valid,
  &.filled,
  &.focused {
    input,
    textarea {
      border: 2px solid #ffffff;
      &:disabled {
        border: 2px solid #333;
      }
    }
    label {
      top: 6px;
      font-size: 13px;
      border: none;
      font-weight: 600;
      transition: 0.15s linear;
    }
  }
  &.switch {
    width: 40px;
    min-height: 20px;
    max-height: 20px;
    max-width: 40px;
    text-align: right;
    background: rgba(219, 68, 55, 1);
    padding: 0;
    background: transparent;
    transition: 0.35s linear;
    &:hover,
    &.valid,
    &.filled,
    &.focused {
      label {
        top: 10px;
      }
      input {
        border: none !important;
        display: none;
      }
    }
    &.checked {
      background: rgba(15, 157, 88, 1);
    }
    input {
      position: absolute;
      background: transparent !important;
      border: none;
      padding: 0;
      margin: 0;
      height: 20px;
      width: 20px;
      max-width: 20px;
      display: none;
    }
    label {
      position: absolute;
      top: 10px;
      left: 0;
      display: block;
      height: 20px;
      width: 16px;
      max-width: 14px;
      border-radius: 50%;
      background: #fff;
      margin: 0;
      &.checked {
        left: 20px;
        transition: 0.35s linear;
      }
    }
  }
  .buttonTogglePassword {
    position: absolute;
    top: 5px;
    right: 0;
    background: transparent;
    border: none;
    height: 47px;
    width: 47px;
    transition: 0.35s;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      padding: 5px;
      box-sizing: border-box;
      transition: 0.35s;
    }
  }
  label {
    font-size: 13px;
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    transition: top 0.3s ease;
    background: #252525;
    display: block;
    padding: 0 3px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.15s linear;
  }
  input,
  textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid #777;
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    outline: none;
    transition: border-color 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    background: #222 !important;
    color: #fff !important;
    resize: none;
    &:disabled {
      border: 2px solid #333;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1b1c inset !important;
    -webkit-text-fill-color: #ffffff !important;
  }
  .searchIcon {
    position: absolute;
    top: 10px;
    left: 5px;
    color: #fff;
    height: 37px;
    width: 37px;
    padding: 0;
    background: transparent;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 7px;
    border: none;
    transition: 0.35s;
    svg {
      width: 100%;
      height: 100%;
    }
    &:disabled {
      margin-left: -100%;
      transition: 0.35s;
    }
  }
  .searchButton {
    position: absolute;
    top: 10px;
    right: 5px;
    color: #1a1b1c;
    height: 37px;
    width: 37px;
    padding: 0;
    background: #fff;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 7px;
    border: none;
    transition: 0.35s;
    svg {
      width: 100%;
      height: 100%;
    }
    &:disabled {
      margin-right: -100%;
      transition: 0.35s;
    }
  }
}
input:disabled {
  &.filled,
  &.focus {
    border: 2px solid #333 !important;
  }
}
.checkbox {
  text-align: left;
  font-size: 13px;
  margin: 20px auto;
  padding: 10px;
  background: #333;
  border-radius: 10px;
  width: 90%;

  input[type="checkbox"] {
    width: 50px;
    height: 20px;
    background: transparent;
    margin: 0 10px 0 0;
    padding: 0;
    border: 2px solid #999;
    border-radius: 5px;
    transition: 0.35s;
    &:checked {
      background: #95d564;
      border: 2px solid #fff;
      transition: 0.35s;
    }
  }
  input[type="radio"] {
    width: 15px;
    height: 15px;
    background: transparent;
    margin: 0 10px 0 0;
    padding: 0;
    border: 2px solid #999;
    border-radius: 50px;
    transition: 0.35s;
    &:checked {
      border: 2px solid rgb(219, 68, 55);
      background-color: rgba(219, 68, 55, 0.3);
      transition: 0.35s;
    }
  }

  &.row {
    margin: 10px 5px 10px 0;
  }
}
.profilePhotoLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.removeProfilePhoto {
  padding: 0;
  margin: 0 0 0 10px;
  border: none;
  background: transparent;
  svg {
    display: block;
    color: #fff;
  }
}
.profilePhotoInput {
  width: 165px;
  height: 165px;
  border-radius: 50%;
  border: 2px solid #999;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  &.hidden {
    display: none;
  }
  input {
    display: none;
  }
  label {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 165px 0 0 0;
    width: 165px;
    display: block;
    background: transparent;
    box-sizing: border-box;
  }
}
.profileImageAdded {
  width: 165px;
  height: 165px;
  min-width: 165px;
  min-height: 165px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #999;
  box-sizing: border-box;
  margin: 0 auto;
}
