.headerFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        background: transparent;
        color: #fff;
        border: none;
        padding: 0;
    }
    &.fullWidth {
        width:100%;
    }
    strong {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}