.flexBox {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  a {
    flex-shrink: 0;
    margin: 0;
    width: fit-content;
  }
  .flexBoxText {
    text-align: left;
    margin: 0 50px;
    width: 50%;
  }
  &.overflowVisible {
    overflow: visible !important;
  }
  &.flexWrap {
    flex-wrap: wrap;
  }
  &.flexMobileNone {
    display: block;
  }
  &.alignCenter {
    align-items: center;
  }
  &.alignStart {
    align-items: start;
  }
  &.justifyCenter {
    justify-content: center;
  }
  &.justifySpace {
    justify-content: space-between;
  }
  &.justifyStart {
    justify-content: flex-start;
  }
  &.justifyEnd {
    justify-content: flex-end;
  }
  &.marginAutoHor {
    margin: 0 auto;
  }
  &.marginNone {
    button,
    button:last-of-type {
      margin: 0;
    }
  }
  &.widthFit {
    width: fit-content;
    button {
      min-width: fit-content;
    }
  }
  &.widthFull {
    min-width: 100% !important;
    width: 100% !important;
  }
  &.marginNone {
    justify-content: flex-end;
  }
  &.scroll {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    .card {
      margin: 0 5px 0 0;
      scroll-snap-align: start;
    }
  }
  a {
    margin: 0;
  }
}
