.btn {
  position: relative;
  min-height: 46px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  margin: 0;
  padding: 16px;
  border-radius: 50px;
  width: 100%;
  transition: 0.35s;
  cursor: pointer;
  width: 100%;
  max-width: 500px;
  letter-spacing: 0.6px;
  span {
    display: block;
  }
  &:disabled {
    opacity: 0.4;
  }
  &.flex {
    display: flex;
    align-items: center;
    &.right {
      display: flex;
      align-items: center !important;
      justify-content: center;
      svg {
        margin-left: 8px;
      }
    }
    &.center {
      display: flex;
      align-items: center !important;
      justify-content: center;
      svg {
        margin-right: 8px;
      }
    }
    &.left {
      svg {
        margin-right: 8px;
      }
    }
    &.justify {
      justify-content: space-between !important;
      svg {
        margin-right: 8px;
      }
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.btnPrimary {
    box-sizing: border-box;
    border: 2px solid #ffffff;
    background: #ffffff;
    color: #1a1b1c;
  }
  &.btnSecondary {
    box-sizing: border-box;
    border: 2px solid #ffffff;
    background: transparent;
    color: #fff;
  }
  &.btnTertiary {
    box-sizing: border-box;
    border: 2px solid transparent;
    background: transparent;
    color: #fff;
  }
  &.btnLink {
    box-sizing: border-box;
    border: none;
    background: transparent;
    color: #1a1b1c;
    padding: 0;
    margin: 0;
    min-height: fit-content;
  }
  &.borderNone {
    border: 0 !important;
  }
  &.btnList {
    position: relative;
    box-sizing: border-box;
    border: none;
    background: transparent;
    color: #aaa;
    padding: 12px 0;
    margin: 0 0 5px;
    min-height: fit-content;
    text-align: left;
    border-radius: 0;
    &.hoverNone {
      background: transparent !important;
    }
    svg {
      margin: 0 8px 0 0;
      font-size: 20px;
      flex-shrink: 0;
    }
    .iconLang {
      color: rgba(15, 157, 88, 1);
      &.none {
        color: #999;
      }
    }
    span {
      display: flex;
      align-items: center;
      span {
        margin: 0 10px 0 0;
        border-radius: 3px;
      }
    }
  }
  &.btnNav {
    position: relative;
    box-sizing: border-box;
    border: none;
    border-radius: 0;
    background: transparent;
    color: #fff;
    width: 100%;
    background: transparent;
    text-align: center;
    font-size: 12px;
    transition: 0.35s linear;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 30px;
      margin: 5px auto 5px auto;
    }
  }
  &.btnMenu {
    box-sizing: border-box;
    border: none;
    background: transparent;
    color: #ffffff;
    padding: 0;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    background: transparent;
    margin: 0;
    padding: 0;
    span {
      width: 100%;
      height: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &.btnBack:hover {
    background: #111;
  }
  &.btnBack {
    min-height: 35px;
    min-width: 35px;
    height: 35px;
    width: 35px;
    padding: 5px;
    box-sizing: border-box;
    background: transparent;
    border: none;
    margin: 0 10px;
    svg {
      height: 100%;
      width: 100%;
      color: #fff;
    }
  }
  &.btnDots:hover {
    background: #111;
  }
  &.btnDots {
    min-height: 35px;
    min-width: 35px;
    height: 35px;
    width: 35px;
    padding: 5px;
    box-sizing: border-box;
    background: transparent;
    border: none;
    margin: 0 10px 0 auto;
    svg {
      height: 100%;
      width: 100%;
      color: #fff;
    }
  }
  &.btnFavorite {
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    padding: 9px;
    margin: 0 0 5px 0;
    svg {
      width: 100%;
      height: 100%;
      color: #fff;
      &.favorite {
        color: rgba(219, 68, 55, 1);
      }
    }
  }
  &.btnClose {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    border: none;
    border-radius: 0 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -2px -2px 0 0;
    background-color: transparent;
    svg {
      width: 100%;
      height: 100%;
      color: #fff;
    }
  }
  &.btnCard {
    width: 100%;
    border: none;
    border-radius: 10px;
    display: flex !important;
    // justify-content: center;
    display: inline-block;
    background: #222;
    svg {
      margin: -2px 10px 0 0;
      color: rgba(219, 68, 55, 1);
    }
    span {
      text-align: left;
      max-width: 70%;
      small {
        padding: 5px 0 0 0 !important;
        color: #555;
        display: block;
        strong {
          color: rgba(219, 68, 55, 1);
        }
      }
    }
  }
  &.btnSelect:hover,
  .focus {
    border: 2px solid #fff;
  }
  &.btnSelect {
    border: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    text-align: left;
    background: #222;
    border: 2px solid #777;
    font-weight: 400;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  &.btnSmall {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-block;
    background: transparent;
    // border: 2px solid #ddd;
    border: none;
    font-weight: 400;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    width: fit-content;
  }
  &.btnSearch {
    min-height: 35px;
    min-width: 35px;
    height: 35px;
    width: 35px;
    padding: 5px;
    box-sizing: border-box;
    background: transparent;
    border: none;
    margin: 0 -10px 0 0;
    svg {
      height: 100%;
      width: 100%;
      color: #1a1b1c;
    }
  }
  &.colorDark {
    // color: #ffffff;
    color: rgba(219, 68, 55, 1);
    border-radius: 10px;
    border-bottom: none !important;
  }
  &.colorTransparent {
    color: #ffffff;
    background: transparent;
    border-radius: 10px;
    border-bottom: none !important;
  }
  &.colorGreen {
    color: #ffffff;
    border-radius: 10px;
    border-bottom: none !important;
    svg {
      color: rgba(15, 157, 88, 1);
    }
  }
  &.colorRed {
    color: #ffffff;
    border-radius: 10px;
    border-bottom: none !important;
    svg {
      color: rgba(219, 68, 55, 1);
    }
  }
  &.colorYellow {
    color: #1a1b1c;
    border-radius: 10px;
    border-bottom: none !important;
    background: rgba(244, 180, 0, 1);
    img {
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 120px;
      height: 120px;
      margin: -10px -20px 0 0;
    }
  }
  &.paddingNone {
    padding: 0;
    width: fit-content;
    height: fit-content;
    min-width: fit-content;
    min-height: fit-content;
  }
}
.btn:hover {
  &.btnSecondary,
  &.btnTertiary {
    background: #ffffff;
    color: #1a1b1c;
  }
  &.btnPrimary {
    background: #fff;
    color: #1a1b1c;
  }
}
