.chatControl {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  padding: 15px 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ::placeholder {
    color: #eee;
  }
  .input {
    width: 100% !important;
    padding: 0;
    input {
      background: #555 !important;
      border: none;
      padding: 14px;
      border-radius: 50px;
    }
  }
  button {
    border: none;
    background: #555;
    color: #fff;
    border-radius: 50px;
    min-height: 47px;
    min-width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: 0.35s;
    margin: 0 0 0 5px;
    svg {
      width: 50%;
      height: 50%;
    }
    &:disabled {
      margin: 0 -60px 0 0;
    }
  }
}

.chat {
  .headerFlex {
    background: #1a1b1c;
    padding: 18px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .chatMessages {
    height: fit-content;
    max-height: 83vh;
    position: fixed;
    bottom: 65px;
    left: 0;
    width: 100%;
    overflow: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    .chatMessage {
      display: flex;
      align-items: flex-end;
      margin-bottom: 5px;
      &.hiddenMessage {
        display: none;
      }
      &.sender {
        .chatMessageText {
          text-align: right;
          margin: 0 0 0 auto;
          box-sizing: border-box;
          word-break: break-all;
          p {
            text-align: right;
            background: rgba(219, 68, 55, 1);
            padding: 10px;
            box-sizing: border-box;
            border-radius: 15px 15px 0 15px;
            min-width: fit-content;
            width: fit-content;
            max-width: 80%;
            margin: 0 0 0 auto;
            display: block;
            font-weight: 500;
            color: #fff;
          }
          small {
            display: block;
            width: 100%;
            text-align: right;
            margin: 5px 0;
            color: #fff;
            animation: scaleUp 0.35s linear;
            font-size: 11px;
          }
        }
      }
      &.receiver {
        img {
          width: 25px;
          height: 25px;
          margin: 0;
          margin-right: 5px;
          border-radius: 50%;
        }
        .chatMessageText {
          text-align: right;
          margin: 0 auto 0 0;
          box-sizing: border-box;
          p {
            text-align: left;
            background: #1a1b1c;
            padding: 8px 10px;
            border-radius: 15px 15px 15px 0;
            box-sizing: border-box;
            margin: 0;
            min-width: fit-content;
            max-width: 80%;
            display: block;
            font-weight: 500;
          }
          small {
            display: block;
            width: 100%;
            text-align: left;
            margin-top: 5px;
            color: #fff;
            animation: scaleUp 0.35s linear;
            font-size: 11px;
          }
        }
      }
    }
  }

  .flexSearch {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin: 0;
    input,
    .input {
      margin: 0;
    }
    .searchSubmitButton {
      top: 2.5px;
      right: 2.5px;
      background: #ff6900;
      transition: 0.35s linear;
      &:disabled {
        opacity: 0.3;
        transition: 0.35s linear;
      }
      svg {
        color: #fff;
      }
    }
  }
}
