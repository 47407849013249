@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
::-webkit-scrollbar {
  display: none;
}
html,
body,
#root {
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: "Montserrat";
  user-select: none;
  scroll-behavior: smooth;
  text-align: center;
  background: #222;
  scroll-behavior: smooth;
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  max-width: 500px;
  article {
    h3 {
      font-weight: 600;
    }
    p {
      font-size: 14px;
    }
    ul {
      color: #fff;
      font-size: 14px;
    }
    text {
      text-decoration: underline;
    }
    text-align: left;
  }
  .inputImage {
    display: none;
  }
  &.lightTheme {
    background: #eee;
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    strong,
    button,
    small {
      color: #1a1b1c;
    }
    .input {
      label {
        color: #1a1b1c;
        background: #eee;
      }
      input:hover,
      .focus,
      .active {
        border: 2px solid #1a1b1c;
      }
      input,
      textarea {
        background: #eee !important;
        color: #1a1b1c !important;
        border: 2px solid #aaa;
        caret-color: #1a1b1c;
        caret-shape: block;
      }
    }
    .notificationsBullet {
      border: 3px solid #eee;
    }
    .tabs {
      .tabButtonsPrimary {
        button {
          border-bottom: 3px solid #ddd;
        }
      }
    }
    .chatMessageText {
      p {
        color: #fff;
      }
    }
    .basicInfo {
      color: #333;
    }
    .headerFlex {
      color: #1a1b1c !important;
    }
    .cardFavoriteText {
      label {
        color: #555;
      }
    }
    .modal {
      &.pageAccount {
        background: #eee !important;
      }
    }
    .logo {
      color: rgba(219, 68, 55, 1);
      &.page {
        background: transparent;
      }
    }
    .backdrop {
      background: rgba(255, 255, 255, 0.5);
    }
    .modal {
      &.age {
        background: rgba(255, 255, 255, 0.8);
      }
      &.chat {
        background: #eee;
      }
    }
    .btnClose {
      svg {
        color: #1a1b1c;
      }
    }
    .modalCenter {
      background: #eee;
    }
    .modalBottom {
      background: #eee;
    }
    .carrouselItem {
      background: #eee;
    }
    .btnFavorite {
      background: rgba(255, 255, 255, 0.5) !important;
    }
    .btnBottom {
      background: linear-gradient(to bottom, transparent 1%, #eee, #eee);
    }
    .btn:hover {
      background: #1a1b1c !important;
      color: #eee !important;
      &.btnList {
        &.hoverNone {
          background: transparent !important;
          color: #1a1b1c !important;
        }
      }
      &.btnNav {
        background: #eee !important;
      }
    }
    .btn {
      color: #1a1b1c;
      background: transparent;
      &.btnBack:hover {
        background: #555 !important;
        svg {
          color: #eee !important;
        }
      }
      &.btnBack {
        svg {
          color: #1a1b1c !important;
        }
      }
      &.btnPrimary {
        border: 2px solid #1a1b1c;
        background: #1a1b1c !important;
        color: #fff;
      }
      &.btnSecondary {
        border: 2px solid #1a1b1c;
        color: #1a1b1c;
      }
    }
    ::placeholder {
      color: #555;
    }
    .chatControl {
      .input {
        input {
          background: transparent !important;
        }
      }
      button {
        background: #1a1b1c;
        color: #fff;
      }
    }
    .page {
      background: #eee;
    }
    .accordionButton {
      color: #333;
    }
    .cardFavorite {
      background: #eee;
    }
    .cardNotification {
      background: #f5f5f5;
    }
    .cardUserInfo {
      background: rgba(255, 255, 255, 0.5);
    }
    .validationPassword {
      background: #ddd;
      color: orangered;
    }
    nav {
      background: #ccc;
      a:hover,
      .active {
        .btn {
          &.btnNav {
            background: #eee;
          }
        }
      }
    }
  }
}
button,
input,
textarea,
select {
  outline: none;
  letter-spacing: 0.5px;
  appearance: none;
  font-family: "Montserrat";
  line-height: 0.9;
  caret-color: #fff;
}
select {
  min-width: 93px;
}
h1,
h2,
h3,
h4,
h5,
p,
label,
small {
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: "Montserrat";
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: 500;
}
strong {
  color: #fff;
  padding: 0;
  margin: 0;
  font-family: "Montserrat";
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: 700;
}
a {
  display: block;
  text-decoration: none;
  color: inherit;
}
svg {
  display: block;
}
img {
  pointer-events: none;
  width: 100%;
  font-size: 0;
}
.validated {
  height: 0;
  margin: 0;
  padding: 0 10px;
  background: #333;
  border-radius: 5px;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  transition: 0.35s linear;
  &.valid {
    height: fit-content;
    display: block;
    align-items: center;
    height: fit-content;
    padding: 10px;
    margin: 5px 0 10px 0;
    transition: 0.35s linear;
    svg {
      background: rgba(15, 157, 88, 1);
    }
  }
  ul {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 10px 5px;
      margin: 0;
      color: #eee;
    }
  }
  svg {
    margin: 0 10px 0 0;
    font-size: 16px;
    flex-shrink: 0;
    border-radius: 30%;
    transition: 0.35s;
    background: #333;
    color: #fff;
    padding: 2px;
    border-radius: 2px;
  }
}
.validationPassword {
  background: #333;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  margin: 15px 0 20px 0;
  overflow: hidden;
  transition: 0.35s linear;
  &.valid {
    height: 0;
    padding: 0 10px;
    margin: 0;
  }
  &.error {
    width: 100%;
    color: rgba(244, 180, 0, 1);
    list-style: none;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
  }
  ul {
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      line-height: 1;
      padding: 5px;
      margin: 0;
      color: #eee;
      &.valid {
        display: none;
      }
      svg {
        margin: 0 10px 0 0;
        font-size: 16px;
        flex-shrink: 0;
        border-radius: 30%;
        transition: 0.35s;
        background: #222;
        color: #fff;
        padding: 2px;
        border-radius: 2px;
      }
    }
  }
}

form {
  width: 100%;
  max-width: 500px;
  .btn {
    margin: 5px 0;
  }
  a {
    width: 100%;
    margin: 5px 0 !important;
  }
}

.flexHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button:hover {
    background: #1a1b1c;
    transition: 0.35s;
  }
  button {
    border: none;
    min-width: 30px;
    min-height: 30px;
    background: #777;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    text-wrap: nowrap;
    line-height: 1;
    transition: 0.35s;
    margin: 0 0 0 10px;
    svg {
      width: 100%;
      height: 100%;
      margin: 0 5px 0 0;
      display: block;
    }
  }
}
.textBlack {
  background: #ffffff;
  padding: 5px 8px;
  color: #1a1b1c;
  border-radius: 50px;
}
.textPadding {
  padding: 10px;
  box-sizing: border-box;
  display: block;
}
.textGrey {
  background: #333;
  padding: 5px 8px;
  color: #fff;
  border-radius: 50px;
}
.status {
  font-size: 13px;
  border-radius: 10px;
  color: #fff;
  padding: 0 5px;
  &.textGreen {
    background: rgba(15, 157, 88, 1);
  }
  &.textYellow {
    background: rgba(244, 180, 0, 1);
  }
  &.textRed {
    background: rgba(219, 68, 55, 1);
  }
}
.list {
  background: #fff;
  margin: 0 0 5px 0;
  padding: 10px;
  p {
    margin: 5px 0;
  }
}
.btnBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  padding: 200px 20px 40px 20px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, transparent 1%, #111, #111);
  z-index: 4;
  h1 {
    font-size: 65px;
    font-weight: 600;
    color: #fff;
    font-family: "Cinzel";
  }
  h2 {
    font-size: 22px;
    font-weight: 400;
    color: #777;
    margin: 8px 0 0 0;
  }
  a {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.logo {
  font-family: "Cinzel";
  color: rgba(219, 68, 55, 1);
  font-size: 35px;
  display: block;
  font-weight: 600;
  width: fit-content;
  margin: 0 auto;
  &.user {
    text-align: left;
    font-size: 20px;
    letter-spacing: 1.2px;
    color: #fff;
    margin: 0;
    display: block;
    width: 100%;
    font-family: "Montserrat";
    .textSmall {
      font-size: 13px;
      letter-spacing: 0.9px;
    }
  }
  &.page {
    margin: 0;
    min-height: fit-content;
    background: transparent;
  }
  &.text {
    width: fit-content;
    display: inline-block;
  }
}
.btnFilters {
  height: 35px;
  width: 30px;
  color: #777;
  margin: 0 0 0 auto;
}
.pageAccount {
  text-align: left;
  &.withBackground {
    background: #222;
    height: 100vh;
    max-height: 100vh;
    width: calc(100% + 20px);
    overflow: auto;
    padding: 20px 10px 0 10px;
    box-sizing: border-box;
    margin-left: -10px;
  }
  .userDetails {
    margin: 0 0 0 10px;
    &.user {
      margin: 0 auto;
      width: calc(90% - 88px);
      span {
        display: block;
        background: #333;
        width: 48%;
        text-align: center;
        color: #fff;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 12px;
      }
    }
    h3 {
      margin: 0;
    }
    small {
      font-size: 13px;
      color: #999;
      &.available {
        color: rgba(15, 157, 88, 1);
      }
      &.busy {
        color: rgba(219, 68, 55, 1);
      }
      &.pending {
        color: rgba(244, 180, 0, 1);
      }
    }
  }
  .userCta {
    position: relative;
    padding: 5px;
    border-radius: 20px;
    padding: 0;
    .btnSecondary {
      width: calc(100% - 51px);
    }
    .btn {
      &.btnFavorite {
        margin: 0 !important;
        background: transparent !important;
        border: 2px solid #fff !important;
        border-radius: 50%;
        height: 46px;
        width: 46px;
      }
    }
  }
  h3 {
    margin: 10px 0;
  }
  .profileImage {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    border: 2px solid #ddd;
    padding: 2px;
    object-fit: cover;
  }
  .userPhotos {
    img {
      width: 100% !important;
      border: none !important;
      border-radius: 20px;
    }
  }
}
.switchStatus {
  position: absolute;
  right: 5px;
  width: fit-content;
  text-align: right;
  background: rgba(219, 68, 55, 1);
  border-radius: 50px;
  padding: 3px;
  min-width: 40px;
  transition: 0.35s linear;
  &.available {
    background: rgba(15, 157, 88, 1);
  }
  button {
    font-size: 11px;
    min-width: 20px !important;
    width: 20px;
    min-height: 20px !important;
    height: 20px;
    padding: 5px 8px;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    transition: 0.35s linear;
    background: #999;
    &.available {
      background: #fff;
      color: #fff;
      transition: 0.35s linear;
    }
    &.busy {
      background: #fff !important;
      color: #fff;
      transition: 0.35s linear;
      margin-right: 100%;
    }
  }
}
.profilePhoto {
  width: 80px !important;
  height: 80px;
  border-radius: 20px;
  border: 2px solid #ddd;
  padding: 2px;
  object-fit: cover;
  &.marginNone {
    margin: 0;
  }
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}
.carrouselItem {
  padding: 0;
  border: none;
  overflow: hidden;
  width: 200px;
  aspect-ratio: 1;
  padding: 0 2.5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #222;
  img {
    border-radius: 10px;
    object-fit: cover;
    height: 100%;
    width: 100%;
    max-height: 200px;
    max-width: 200px;
  }
}
.profilePhoto {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.bannerTop {
  position: relative;
  top: 0px;
  padding: 10px 0;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
  background: rgba(219, 68, 55, 1);
  z-index: 3;
  p {
    color: #1a1b1c;
    width: 100vw;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 10px;
    }
    a {
      display: inline;
      color: #fff;
    }
  }
}

.emptyStateIcon {
  transform: scale(0.5);
}

.resetPasswordToast {
  margin: 15px 0;
  a {
    display: inline;
    font-weight: 600;
    color: rgba(244, 180, 0, 1);
  }
}
.cardElement:hover,
.focus {
  border: 2px solid #fff !important;
}
.cardElement {
  color: #fff !important;
  padding: 12px !important;
  border: 2px solid #777 !important;
  border-radius: 10px;
  iframe {
    color: #fff !important;
  }
}
.cardElement.PaymentRequestButton {
  display: none;
}
select {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 2px solid #777;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  background: #252525 !important;
  color: #fff !important;
  &:disabled {
    border: 2px solid #333;
  }
}
input {
  color: #fff !important;
}
.textYellow {
  color: rgba(244, 180, 0, 1);
}
.textGreen {
  color: rgba(15, 157, 88, 1);
}
.textGradient {
  font-size: 28px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8e2de2, red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.textBig {
  font-size: 28px;
}

.inviteTextarea {
  width: 100%;
  height: 65px;
  text-wrap: wrap;
  color: #fff;
  background: #333;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  user-select: text !important;
  border: none;
  resize: none;
  margin: 0 0 10px 0;
  outline-color: rgba(15, 157, 88, 1);
}
.fi {
  border-radius: 3px;
}
.getToken {
  user-select: text;
  word-wrap: break-word;
  color: transparent;
}
.cardUser:last-of-type {
  &.list {
    border: none;
  }
}
.cardUser {
  &.list {
    background: #000 !important;
    display: flex;
    border-bottom: 1px solid #333;
    padding: 10px;
    &.user {
      padding: 0;
      border: none;
      .cardUserInfo {
        padding: 0;
        width: 100%;
        display: block;
        background: transparent;
      }
    }
    .cardUserInfo {
      padding: 0 0 0 10px;
      text-align: left;
      width: calc(100% - 50px);
      h3 {
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 600;
      }
      .basicInfo {
        background: rgba(0, 0, 0, 0.9);
        width: fit-content;
        padding: 5px 8px;
        border-radius: 50px;
        margin: 2px 2px 2px 0;
        display: flex;
        align-items: center;
        font-size: 11px;
        font-weight: 600;
        flex-shrink: 0;
        color: #fff;
        &.available {
          background: rgba(15, 157, 88, 1);
          color: #fff;
        }
        &.busy {
          background: rgba(219, 68, 55, 1);
          color: #fff;
        }
        svg {
          margin: 0 5px 0 0;
          font-size: 16px;
        }
      }
    }
    img {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-radius: 10px;
      background: #222;
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.textJoined {
  color: #555;
  font-size: 11px;
}
.textRed {
  color: rgba(219, 68, 55, 1);
}
.textLeft {
  text-align: left;
  &.withPadding {
    padding: 0 10px;
  }
}
.textSmall {
  font-size: 13px;
  color: #999;
}
.boxError {
  background: rgba(244, 180, 0, 1);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  font-size: 13px;
  margin: 0 0 5px 0;
  p {
    color: #1a1b1c;
  }
  button:hover {
    background: transparent !important;
  }
  button {
    color: #1a1b1c !important;
    font-size: 13px;
    min-height: fit-content;
    padding: 10px 0 0 0;
  }
}

input[type="range"] {
  width: 90%;
  margin: 0 auto;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    background: #555;
  }

  &::-webkit-slider-runnable-track[value] {
    background: #cbffa3;
  }
  &::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    border: 2px solid rgb(219, 68, 55);
    background-color: rgba(219, 68, 55, 0.6);
    -webkit-appearance: none;
    margin-top: -8px;
  }
  &::-moz-range-track {
    width: 100%;
    height: 3px;
    background: #555;
    border-radius: 25px;
  }
  &::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    border: 2px solid rgb(219, 68, 55);
    background-color: rgba(219, 68, 55, 0.6);
  }
  &::-ms-track {
    width: 100%;
    height: 3px;
    background: #555;
  }
  &::-ms-fill-lower {
    background: #d3d3d3;
    border-radius: 50px;
  }
  &::-ms-fill-upper {
    background: #d3d3d3;
    border-radius: 50px;
  }
  &::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50px;
    border: 2px solid rgb(219, 68, 55);
    background-color: rgba(219, 68, 55, 0.6);
  }
}
.rangeDouble {
  border-radius: 50px 0 0 50px;
  position: absolute;
  width: 90%;
  padding: 0;
  z-index: 3;
}
.rangeDouble:nth-child(2) {
  border-radius: 0 50px 50px 0;
  z-index: 2;
}
.appLogo {
  width: 32%;
  max-width: 150px;
  margin: -10px 5px -10px 5px;
}
.cardNotification {
  display: flex;
  align-items: center;
  background: #191919;
  border-radius: 5px;
  text-align: left;
  padding: 15px 10px;
  margin: 5px 0;
  font-size: 13px;
  svg {
    min-width: 30px !important;
    min-height: 45px !important;
    margin: 0 10px 0 0;
  }
  &.message {
    background: transparent;
    &.notViewed {
      background: #1a1b1c;
    }
    p {
      padding-top: 5px;
    }
    img {
      width: 45px !important;
      height: 45px !important;
      border-radius: 50%;
      margin: 0 10px 0 0;
      background: #333;
    }
  }
}
.photoPreviewWrapper:nth-child(5) {
  width: 20%;
  margin: 0;
}
.photoPreviewWrapper {
  position: relative;
  width: calc(19vw - 5px);
  margin: 0 5px 0 0;
  height: calc(19vw - 5px);
  border: 2px solid #fff;
  border-radius: 20px;
  padding: 0 !important;
  overflow: visible;
  img {
    width: 100% !important;
    height: calc(19vw - 5px);
    border: none !important;
    border-radius: 20px;
    margin: 0 !important;
    padding: 0 !important;
    object-fit: cover;
  }
}
.removePhotoButton {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #999;
  border-radius: 50px;
  background: transparent;
  color: #999;
  padding: 0;
  width: 16px;
  height: 16px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    padding: 1px;
    box-sizing: border-box;
  }
}

.multiplePhotosButton {
  width: calc(20vw - 5px);
  aspect-ratio: 1/1;
  box-sizing: border-box;
  border: 2px solid #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  svg {
    margin: 0 auto;
  }
}
.getAppBanner {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 500px;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: #333;
  box-sizing: border-box;
  z-index: 8;
  box-shadow: 0 10px 15px #111;
  border-radius: 10px;
  text-align: left;
  font-size: 16px;
  small {
    color: #999;
    font-size: 11px;
  }
  .btnGetApp {
    border: none;
    border-radius: 5px;
    padding: 8px 14px;
    background: rgba(219, 68, 55, 1);
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }

  span {
    display: flex;
    align-items: center;
    .btnHide {
      color: #fff;
      background: transparent;
      padding: 3px;
      border: none;
      margin: 0 5px 0 0;
      height: 60px;
      min-height: 100%;
    }
    img {
      margin: 0 10px 0 0;
      max-width: 60px;
      max-height: 60px;
      border-radius: 30%;
    }
    p {
      flex-shrink: 0;
    }
  }
}
.notificationsBullet {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: rgb(219, 68, 55);
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% + 15px);
  transform: translate(-50%, -50%);
  border: 3px solid #222;
}
.plansSwiper {
  .swiper-slide {
    min-height: 60vh;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .swiper-pagination-bullet-active {
    background: rgb(219, 68, 55);
  }
}
